import takeActionHero from '../assets/icons/icon-microscope.svg';
import geneticTestingIcon from '../assets/icons/icon-genetic-testing.svg';
import monitoringIcon from '../assets/icons/icon-monitoring.svg';
import diagnosisClipboard from '../assets/icons/icon-diagnosis_clipboard.svg';

const takeActionCopy = {
  pageSeoTitle: 'How Is Von Hippel-Lindau (VHL) Disease Diagnosed?',
  pageDescription: 'Learn about how von Hippel-Lindau (VHL) disease is diagnosed using genetic testing or clinical assessments. See what happens after a VHL disease diagnosis.',
  pageKeywords: 'how is vhl diagnosed, how is von hippel-lindau disease diagnosed',

  hero: {
    title: 'How is VHL diagnosed?',
    imgSrc: takeActionHero,
    imgAlt: '',
    underline: 'green',
  },

  aboutSection: {
    title: 'Doctors diagnose people with von Hippel-Lindau (VHL) disease using genetic testing and clinical assessment.',
    graphic: geneticTestingIcon,
    graphicAlt: '',
    copy: `<p><strong>Genetic testing is the most accurate way to confirm if someone has VHL disease</strong>. It looks at DNA to search for the mutation in the VHL gene.</p> 
    <p>Doctors do genetic testing by taking a small blood sample. From there, the genetic material in the blood is checked for a mutated VHL gene. </p>
    <p>Genetic testing may be performed on children and adults with a family history of VHL disease. Doctors can test children during the mother’s pregnancy or after the child is born. By diagnosing children early, pediatricians can start screening or surveillance sooner.</p>`,   
  },

  afterDiagnosisSection: {
    title: 'What happens after a VHL diagnosis?',
    graphic: monitoringIcon,
    graphicAlt: '',
    topSection: `<p><strong>VHL disease is a condition that can be managed over a lifetime. Your doctor will work in partnership with other healthcare providers who will help manage all aspects of your condition.</strong> Regular checkups can help find new VHL disease-associated tumors when they’re small and don’t cause as many serious problems.  </p>`,
    copy: `<p><strong>Cysts and tumors caused by VHL disease can appear unpredictably. Ongoing monitoring can help your doctor:</strong></p> 
    <ul><li>Keep an eye on any existing cysts or tumors </li><li>Detect new cysts and tumors faster</li><li>Uncover cancerous tumors</li></ul>`,   
  },

  earlyDiagnosisSection: {
    title: 'Why is early diagnosis and detection of VHL disease so important?',
    graphic: diagnosisClipboard,
    graphicAlt: '',
    copy: `<p>VHL disease can be serious, even if the cysts and tumors it causes aren’t cancerous. <strong>Approximately 97% of people who have a VHL gene mutation will get related symptoms by the age of 65.</strong></p>
    <p><strong>That’s why getting diagnosed early and doing active surveillance—regular VHL checkups with a doctor—are so important.</strong> Finding cysts and tumors early can help your doctor manage them.</p>`,
  },

  leftAndRightButton: {
    left: {
      label: 'VHL and genetics',
      to: '/vhl-and-genetics/',
      shadow: 'lime'
    },
  }

};

export default takeActionCopy;
