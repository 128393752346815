import styled from 'styled-components';
import CtaSection from '../../components/elements/CtaSection';
import Hero from '../../components/elements/Hero';
import ShadowBox from '../../components/elements/ShadowBox';
import PageSection from '../../components/layout/PageSection';
import { respondTo } from '../shared/breakpoints';
import { greyCopy, dotsBlue, greyBorder, dotsLightBlue } from '../shared/colors';

export const DiagnosisHero = styled(Hero)`
    ${respondTo.sm`
        h1 {
            text-align: left;
        }
    `}
`;

export const AboutCtaSection = styled(CtaSection)`
    padding: 37px 0;
    ${respondTo.sm`
        padding: 68px 0 50px 0;
    `}
    h2 {
        margin-right: -12px;
        ${respondTo.sm`
            margin-right: 0;
        `}
    }
`;

export const AfterDiagnosisSection = styled(CtaSection)`
    padding: 33px 0;
    ${respondTo.sm`
        padding: 53px 0 60px 0;
    `}
    h2 {
        margin-bottom: 0;
        ${respondTo.sm`
            margin-bottom: 34px;
        `}
    }
    .pageSectionWrapper {
        position: relative;
        &:before, &:after {
            content: '';
            position: absolute;
            height: 2px;
            background-color: ${greyBorder};
            width: calc(100% - 30px);
            left: 15px;
            top: -33px;
            ${respondTo.sm`
                top: -53px;
                left: 0;
                width: 100%;
            `}
        }
        &:after {
            top: calc(100% + 33px);
            ${respondTo.sm`
                top: calc(100% + 60px);
            `}
        }
    }
    .ctaContentContainer {
        padding-top: 24px;
        gap: 0px;
        ${respondTo.sm`
            gap: 44px;
        `}
        p {
            width: 90%;
        }
        ul {
            padding-left: 20px;
        }
    }
`;

export const EarlyDiagnosisSectionMain = styled(CtaSection)`
    padding: 33px 0;
    ${respondTo.sm`
        padding: 53px 0 0 0;
    `}
    h2 {
        margin-bottom: 0;
        ${respondTo.sm`
            margin-bottom: 34px;
        `}
    }
    .pageSectionWrapper {
        position: relative;
        &:before, &:after {
            content: '';
            position: absolute;
            height: 2px;
            width: calc(100% - 30px);
            left: 15px;
            top: -33px;
            ${respondTo.sm`
                top: -53px;
                left: 0;
                width: 100%;
            `}
        }
        &:after {
            top: calc(100% + 33px);
            ${respondTo.sm`
                top: calc(100% + 60px);
            `}
        }
    }
    .ctaContentContainer {
        padding-top: 24px;
        gap: 0px;
        ${respondTo.sm`
            gap: 44px;
        `}
        p {
            width: 90%;
        }
        ul {
            padding-left: 20px;
        }
    }
`;

export const EarlyDiagnosisSection = styled(PageSection)`
    padding: 33px 0 0 0;
    ${respondTo.sm`
        padding: 51px 0 0 0;
    `}
    h2 {
        color: ${dotsBlue};
        font-family: 'noto-serif';
        font-size: 27px;
        line-height: 38px;
        margin-bottom: 18px;
        ${respondTo.sm `
            margin-bottom: 34px;
        `}
    }
    p {
        font-size: 17px;
        line-height: 26px;
    }
    
`;

export const EarlyDiagnosisCopyContainer = styled.div`
    display: flex;
    gap: 0;
    flex-direction: column;
    align-items: center;
    ${respondTo.sm`
        flex-direction: row;
        gap: 55px;
    `}
`;

export const EarlyDiagnosisShadowBox = styled(ShadowBox)`
    width: auto;
    height: auto;
    padding: 20px 14px 20px 22px;
    text-align: center;
    color: ${dotsBlue};
    margin: 16px 0 0 -14px;
    box-shadow: 12px 13px 0px ${dotsLightBlue};
    ${respondTo.sm`
        flex-direction: row;
        gap: 55px;
        padding: 18px;
        width: 42%;
        height: auto;
        margin: 0;
        box-shadow: 10px 12px 0px ${dotsLightBlue};
    `}
    h2{ 
        font-size: 58px;
        line-height: 50px;
        margin-bottom: 12px;
        font-weight: 800;
        font-family: 'noto-sans';
    }
    p {
        font-weight: bold;
    }
`;

export const EarlyDiagnosisCopy = styled.div`
    color: ${greyCopy};
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
        margin-top: 16px;
        &:first-child {
            margin-top: 30px;
            ${respondTo.sm`
                margin-top: -6px;   
            `}
        }
    }
    ${respondTo.sm`
        width: 58%;
    `}
`;