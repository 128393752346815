import React from 'react';
import Hero from '../components/elements/Hero';
import howIsVHLdiagnosed from '../copy/how-is-vhl-diagnosed';
import * as s from '../styles/pages/take-action';
import TalkToDoctorShortSection from '../components/elements/TalkToDoctorShortSection';
import LeftAndRightButtonSection from '../components/elements/LeftAndRightButtonSection';
import PageSeo from '../components/elements/Seo/PageSeo';

// TO DO: copy looks to be pulling from a confusing file-name - to update
const TakeActionPage = () => {
  return (
    <>
    <PageSeo pageTitle={ howIsVHLdiagnosed.pageSeoTitle } pageDescription={ howIsVHLdiagnosed.pageDescription } pageKeywords={ howIsVHLdiagnosed.pageKeywords }>
      <script type="application/ld+json">{`"@context":"http://schema.org/","url":"https://www.coulditbevhl.com/how-is-vhl-diagnosed/","@type":"MedicalWebPage","name":"How is VHL diagnosed?","description":"Doctors diagnose people with von Hippel-Lindau (VHL) disease using genetic testing and clinical assessment."`}</script>
      <script type="application/ld+json">{`"@context":"http://schema.org/","url":"https://www.coulditbevhl.com/how-is-vhl-diagnosed/","@type":"MedicalWebPage","name":"What happens after a VHL diagnosis?","description":"VHL disease is a condition that can be managed over a lifetime. Your doctor will work in partnership with other healthcare providers who will help manage all aspects of your condition. Regular checkups can help find new VHL disease-associated tumors when they’re small and don’t cause as many serious problems."`}</script>
      <script type="application/ld+json">{`"@context":"http://schema.org/","url":"https://www.coulditbevhl.com/how-is-vhl-diagnosed/","@type":"MedicalWebPage","name":"Why is early diagnosis and detection of VHL disease so important?","description":"VHL disease can be serious, even if the cysts and tumors it causes aren’t cancerous. Approximately 97% of people who have a VHL gene mutation will get related symptoms by the age of 65."`}</script>
      <script type="application/ld+json">{`"@context":"http://schema.org/","url":"https://www.coulditbevhl.com/how-is-vhl-diagnosed/","@type":"MedicalAudience","audienceType":"Patient","geographicArea":"This site is intended only for residents of the United States, its territories, and Puerto Rico."`}</script>
      <link rel="canonical" href="https://www.coulditbevhl.com/how-is-vhl-diagnosed/"/>
    </PageSeo>
      <Hero {...howIsVHLdiagnosed.hero} />
      <s.AboutCtaSection {...howIsVHLdiagnosed.aboutSection}/>
      <s.AfterDiagnosisSection {...howIsVHLdiagnosed.afterDiagnosisSection} borderTop/>
      <s.EarlyDiagnosisSectionMain {...howIsVHLdiagnosed.earlyDiagnosisSection} />
      <TalkToDoctorShortSection />
      <LeftAndRightButtonSection {...howIsVHLdiagnosed.leftAndRightButton} />
    </>
  )
}

export default TakeActionPage;
